import React, { useContext, useEffect, useState } from "react";
import Button from '../ui/Buttontwo'
import Label from "../forms/Label";
import CustomHook from "../../CustomHook";
import UserContext from "../Controller";
import { toast } from "react-toastify";

function Billings() {

    let [goal, setGoal] = useState('');

    const { fetchUserData } = CustomHook(); // Fetch the fetchUserData function from your custom hook

    const { currentUserDetails } = useContext(UserContext); // Get currentUserDetails from UserContext
  
    // Fetch user data when the component mounts
    useEffect(() => {
      fetchUserData();
    }, []);
  

    // Ensure currentUserDetails is not null or undefined before destructuring
    const {
        goals,
        user: {
            plan,
        } = {},
    } = currentUserDetails || {};

    const handleInputChange = (e) => {
        const { value } = e.target;
        setGoal(value)
    }

    const handleGoalUpdate = async (e) => {
        e.preventDefault();

        const userGoal = {
            userNewGoal: goal
        }

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userGoal),
            credentials: "include",
        }

        try {

            if (goal !== '') {
                const response = await fetch('https://api.thementornextdoor.com/auth/updateUser-goal', options)
                const result = await response.json();
    
                if (!response.ok) {
                    throw new Error(result.msg || "Something went wrong");
                }
                toast.success(result.msg);
                setGoal('')
            } else {
                toast.error('Kindly fill the goal input');
            }
        } catch (error) {
            toast.error(error.msg);
        }
    }

    return (
        <>
            <div className="p-4 xs:p-6">
                <div className="mb-5 empty:mb-0">
                    <form className="w-full items-center justify-center gap-3 grid md:grid-cols-2 grid-cols-1">
                        <div className="py-2 flex flex-col">
                            <Label htmlFor="primaryGoal" className="mb-2">
                                Current Goal
                            </Label>
                            <input type="text" onChange={handleInputChange} id="primaryGoal" value={goals[0].description} name="primaryGoal" className="w-full h-[35px] focus:focus:outline-[#2563EB] placeholder:text-[#475569] placeholder:text-[12px] placeholder:capitalize focus:outline-1 focus:border-none border-[#E4EAF1] border-[1px] focus:ring rounded-md" disabled />
                        </div>

                        <div className="py-2 flex flex-col">
                            <Label htmlFor="updateGoal" className="mb-2">
                                New Goal
                            </Label>
                            <input type="text" value={goal} onChange={handleInputChange} id="updateGoal" name="updateGoal" placeholder="Set your new goal..." className="w-full h-[35px] focus:focus:outline-[#2563EB] placeholder:text-[#475569] placeholder:text-[12px] placeholder:capitalize focus:outline-1 focus:border-none border-[#E4EAF1] border-[1px] focus:ring rounded-md" />
                        </div>

                        <Button
                            onClick={handleGoalUpdate}
                            className="bg-blue-600 text-white hover:bg-blue-800"
                        >
                            Update Goal
                        </Button>
                    </form>
                </div>
                <div className="flex flex-col">
                    <h6 className="text-xs font-bold text-slate-700 dark:text-white mb-3">
                        Current Package
                    </h6>
                    <div className="flex flex-wrap justify-between items-center border border-slate-200 dark:border-slate-800 px-5 py-4 rounded-md gap-3">
                        <div className="">
                            <h4 className="text-lg font-bold text-slate-600 dark:text-slate-200">
                                $14.59
                                <span className="text-xs font-bold text-slate-400 ms-2">
                                    Per Month
                                </span>
                            </h4>
                            <h3 className="text-xl font-bold text-slate-700 dark:text-white mb-1">
                                Premium Plan
                            </h3>
                            <p className="text-sm text-slate-500 dark:text-slate-400">
                                Next billing is on 10 Apr 2024
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Billings;
