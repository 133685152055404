import React from 'react'
import { Accordion} from "../Components/Accordion";

const Faq = () => {
  return (
        <section className="pt-16 mb-7 w-full md:pt-20 lg:pt-24 xl:pt-28 pb-2 bg-white dark:bg-slate-900 overflow-hidden">
                <div className='px-3'>
                    <div className="flex flex-wrap items-center justify-center pb-8 lg:pb-10">
                        <div className="sm:w-2/3 md:w-3/5 lg:w-2/5 text-center text- mx-auto">
                            <h3 className="text-3xl leading-tight font-bold text-slate-700 dark:text-white mb-3">You might have some questions, lets talk about it</h3>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center -m-3 md:-m-4" >
                        <div className="w-full xl:w-3/4 p-3 md:p-4">
                            <Accordion className="flex flex-col gap-3">
                                <Accordion.Item
                                    size="lg"
                                    className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                                    header={
                                        "What can I do with The Mentor Next Door?"
                                    }
                                    initialEntered
                                >
                                    <div className="max-w-3xl">    
                                        <p className="text-base/7">
                                        The Mentor Next Door is an AI-powered mentoring platform designed to help you set, track, and achieve your goals. You can use it to define your objectives, create actionable plans, monitor your progress, and receive insightful progress reports. Whether you’re looking to improve in your career, personal development, or learning new skills, our AI mentor is here to guide you every step of the way.
                                        </p>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item
                                    size="lg"
                                    className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                                    header={
                                        "What is The Mentor Next Door good for?"
                                    }
                                >
                                    <div className="max-w-3xl">    
                                        <p className="text-base/7">
                                        The Mentor Next Door is perfect for anyone seeking structured guidance to reach their goals. It’s especially beneficial for individuals who thrive with clear milestones, actionable feedback, and regular progress tracking. Whether you’re focusing on professional development, personal growth, or skill acquisition, the platform provides a consistent and personalized path to success.
                                        </p>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item
                                    size="lg"
                                    className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                                    header={
                                        " Is there a contract or long-term commitment?"
                                    }
                                >
                                    <div className="max-w-3xl">    
                                        <p className="text-base/7">
                                        You have the flexibility to choose between a monthly or annual subscription. There’s no long-term contract required—just pick the option that works best for you. If you opt for the annual plan, you’ll enjoy a discount compared to the monthly rate. You can cancel anytime, and your access will continue until the end of the current billing cycle, whether monthly or yearly.
                                        </p>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item
                                    size="lg"
                                    className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                                    header={
                                        "Who is The Mentor Next Door designed for?"
                                    }
                                >
                                    <div className="max-w-3xl">    
                                        <p className="text-base/7">
                                        The Mentor Next Door is ideal for professionals, students, and lifelong learners who want personalized, AI-driven mentorship. If you’re someone who values clear goal-setting, consistent feedback, and the ability to track your progress over time, this platform is for you. It’s designed to empower anyone motivated to grow and achieve their full potential.
                                        </p>
                                    </div>
                                </Accordion.Item>
                                <Accordion.Item
                                    size="lg"
                                    className="bg-white dark:bg-slate-950 border border-slate-100 dark:border-slate-950 shadow rounded-2xl"
                                    header={
                                        "What makes The Mentor Next Door effective?"
                                    }
                                >
                                    <div className="max-w-3xl">    
                                        <p className="text-base/7">
                                        The Mentor Next Door is powered by insights derived from over 6,000 real-life mentoring sessions, ensuring our AI provides you with proven, impactful guidance tailored to your needs. Whether you choose a monthly or annual plan, you’ll benefit from a platform built on extensive experience, designed to help you achieve your goals with confidence and clarity.
                                        </p>
                                    </div>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default Faq
