import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import logo from "../Assets/Images/mentorLogo.png";
import displayPic from "../Assets/Images/MentorProfilePics.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomHook from "../CustomHook";
import UserContext from "./Controller";


const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ headerNavType }) {

  let navigate = useNavigate(); // Initialize navigate function for navigation

  const { fetchUserData } = CustomHook(); // Fetch the fetchUserData function from your custom hook

  const { currentUserDetails } = useContext(UserContext); // Get currentUserDetails from UserContext

  // Fetch user data when the component mounts
  useEffect(() => {
    fetchUserData();
  }, []);

  // Ensure currentUserDetails is not null or undefined before destructuring
  const { name, } = currentUserDetails || {};

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  let [showProfileMenu, setShowProfileMenu] = useState(false);

  const handleLogout = async () => {
    try {
      // Set login status to false
      localStorage.setItem('loginStatus', 'false');

      toast.success('Logout successful');

      // Redirect to login page
      navigate('/userAuth/login');
    } catch (error) {
      console.log(error.message);
    }
  };


  const changeprofileDropdownVisibility = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const location = useLocation();

  return (
    <header className="bg-white w-full">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a
            href="#"
            className="-m-1.5 p-1.5"
          >
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto" src={logo} alt="" />
          </a>
        </div>

        {headerNavType === "profile" ? (
          <aside className="items-center relative justify-center flex lg:flex-1 lg:justify-end">
            <div
              className="w-[50px] cursor-pointer h-[50px] rounded-full border-2 border-[#CBD5E1] flex items-center justify-center"
              onClick={changeprofileDropdownVisibility}
            >
              <img
                src={displayPic}
                alt="profile img"
                className="w-[85%] h-[85%] rounded-full"
              />
            </div>

            {showProfileMenu && (
              <ul className="absolute lg:w-[35%] z-50 top-[50px] right-5 rounded-md gap-2 items-center justify-center flex flex-col py-3 bg-white shadow-md border-2 border-[#CBD5E1]">
                <li className="w-full flex border-b-2 border-[#E2E8F0] items-center justify-between py-2 px-1">
                  <div className="w-[50px] cursor-pointer h-[50px] rounded-full border-2 border-[#CBD5E1] flex items-center justify-center">
                    <img
                      src={displayPic}
                      alt="profile img"
                      className="w-[85%] h-[85%] rounded-full"
                    />
                  </div>
                  <h3 className="py-3 text-sm text-left w-[70%] text-[#334155] font-bold capitalize">
                    {name}
                  </h3>
                </li>
                {location.pathname === '/user-profile' ? <Link
                  to="/Userchatbot"
                  className="w-[90%] items-center cursor-pointer justify-center"
                >
                  <li className="w-[100%] items-center flex py-2 justify-start gap-2 text-[12px] text-[#64748B] pl-2 font-bold capitalize">
                    {" "}
                    <PermIdentityIcon
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    />{" "}
                    Chat Bot
                  </li>
                </Link> : <Link
                  to="/user-profile"
                  className="w-[90%] items-center cursor-pointer justify-center"
                >
                  <li className="w-[100%] items-center flex py-2 justify-start gap-2 text-[12px] text-[#64748B] pl-2 font-bold capitalize">
                    {" "}
                    <PermIdentityIcon
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    />{" "}
                    profile
                  </li>
                </Link>}
                <li
                  onClick={handleLogout}
                  className="w-[90%] items-center cursor-pointer py-2 justify-center gap-2 text-[12px] text-[#64748B] pl-2 font-bold capitalize"
                >
                  {" "}
                  <PowerSettingsNewIcon
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  />{" "}
                  log out
                </li>
              </ul>
            )}
          </aside>
        ) : headerNavType === "login" ? (
          <div className="lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/userAuth/login"
              className="text-sm font-semibold leading-6 text-gray-900 hover:text-[#2563EE]"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        ) : headerNavType === "registration" ? (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/userAuth/registration"
              className="text-sm font-semibold leading-6 text-gray-900 hover:text-[#2563EE]"
            >
              Sign up <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        ) : null}
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a
              href="https://scribbler-ai-landing-page.vercel.app/"
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src={logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">

              {headerNavType === "profile" ? (
                <aside className="items-center relative justify-center hidden lg:flex lg:flex-1 lg:justify-end">
                  <div
                    className="w-[50px] cursor-pointer h-[50px] rounded-full border-2 border-[#CBD5E1] flex items-center justify-center"
                    onClick={changeprofileDropdownVisibility}
                  >
                    <img
                      src={displayPic}
                      alt="profile img"
                      className="w-[85%] h-[85%] rounded-full"
                    />
                  </div>

                  {showProfileMenu && (
                    <ul className="absolute lg:w-[35%] z-50 top-[50px] right-5 rounded-md gap-2 items-center justify-center flex flex-col py-3 bg-white shadow-md border-2 border-[#CBD5E1]">
                      <li className="w-full flex border-b-2 border-[#E2E8F0] items-center justify-between py-2 px-1">
                        <div className="w-[50px] cursor-pointer h-[50px] rounded-full border-2 border-[#CBD5E1] flex items-center justify-center">
                          <img
                            src={displayPic}
                            alt="profile img"
                            className="w-[85%] h-[85%] rounded-full"
                          />
                        </div>
                        <h3 className="py-3 text-sm text-center w-[70%] text-[#334155] font-bold capitalize">
                          Phillip Burke
                        </h3>
                      </li>
                      <Link
                        to="/user-profile"
                        className="w-[90%] items-center cursor-pointer justify-center"
                      >
                        <li className="w-[100%] items-center flex py-2 justify-start gap-2 text-[12px] text-[#64748B] pl-2 font-bold capitalize">
                          {" "}
                          <PermIdentityIcon
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          />{" "}
                          profile
                        </li>
                      </Link>
                      <li
                        onClick={handleLogout}
                        className="w-[90%] items-center cursor-pointer py-2 justify-center gap-2 text-[12px] text-[#64748B] pl-2 font-bold capitalize"
                      >
                        {" "}
                        <PowerSettingsNewIcon
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        />{" "}
                        log out
                      </li>
                    </ul>
                  )}
                </aside>
              ) : (
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                  <Link
                    to="/userAuth/login"
                    className="text-sm font-semibold leading-6 text-gray-900 hover:text-[#2563EE]"
                  >
                    Log in <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
